<template>
  <section>
    <header>
      <h1>TAVI CMS</h1>
      <div>
        <el-button native-type="button" @click="exportData" :disabled="isSubmitting">Export case data</el-button>
        <el-button native-type="button" @click="doSignOut" :disabled="isSubmitting">Sign out</el-button>
      </div>
    </header>
    <div class="content">
      <router-view />
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'index',
  data: () => ({
    isSubmitting: false
  }),
  methods: {
    ...mapActions({
      signOut: 'taviCms/signOut',
      fetchCaseData: "taviCms/fetchCaseData"
    }),
    async exportData () {
      this.isSubmitting = true;
      const exportData = await this.fetchCaseData();
      const fileName = `caseData-${new Date}.json`
      const el = document.createElement('a')
      el.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportData)));
      el.setAttribute('download', fileName)
      el.style.display = 'none'
      document.body.appendChild(el)
      el.click()
      document.body.removeChild(el)
      this.isSubmitting = false;
    },
    async doSignOut () {
      this.isSubmitting = true;
      await this.signOut();
      this.isSubmitting = false;
    }
  },
};
</script>

<style>
  * {
    box-sizing: border-box;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
  }

  header {
    background: rgb(84, 92, 100);
    padding: 0.25rem 2rem;
    width: 100vw;
    position: fixed;
    line-height: 1;
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    z-index: 9000;
  }

  .content {
    margin: 0 auto;
    max-width: 50rem;
    padding: 1rem;
    padding-top: 6rem;
  }
</style>
